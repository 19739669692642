import $ from 'jquery';

$(document).ready(function () {

	$(window).scroll(function () {
		var sticky = $('header'),
			scroll = $(window).scrollTop();

		if (scroll >= 100) {
			sticky.addClass('fixed');
		} else {
			sticky.removeClass('fixed');

		}
	});

	//HAMBURGER

	if (window.matchMedia("(max-width: 1024px)").matches) {
		//HAMBURGER

		$("#menu-toggle").click(function(e) {
			e.preventDefault();
			$("header").toggleClass("toggled");
			$("#sidebar-wrapper").toggleClass("toggled");
		});

		$("#sidebar-wrapper .close").click(function(e) {
			e.preventDefault();
			$("header").removeClass("toggled");
			$("#sidebar-wrapper").toggleClass("toggled");
		});

		$('.sidebar-nav .dropdown-menu > li > .dropdown-menu').parent().addClass('dropdown-submenu').find(' > .dropdown-item').addClass('dropdown-toggle');

		$('.menu-item').on("click", function(e) {
			$('.dropdown-submenu.show').removeClass('show').find('.dropdown-menu, .dropdown-toggle').removeClass('show');
			$('.dropdown-submenu > a.dropdown-toggle[aria-expanded="true"]').attr('aria-expanded', 'false');
		});

		$('.dropdown-submenu > a.dropdown-toggle').on("click", function(e) {
			e.preventDefault();

			var parentLi = $(this).parent();
			var dropdownLink = $(this);

			if (parentLi.hasClass('show')) {
				parentLi.removeClass('show');
				parentLi.find('.dropdown-menu, .dropdown-toggle').removeClass('show');
				dropdownLink.attr('aria-expanded', 'false');
			} else {
				// Fermez tous les autres sous-sous-menus ouverts du même parent
				var siblingLinks = parentLi.siblings('.dropdown-submenu.show').removeClass('show').find('.dropdown-menu, .dropdown-toggle').removeClass('show');
				siblingLinks.parent().find('> a.dropdown-toggle[aria-expanded="true"]').attr('aria-expanded', 'false');

				parentLi.addClass('show');
				parentLi.find('.dropdown-menu, .dropdown-toggle').addClass('show');
				dropdownLink.attr('aria-expanded', 'true');
			}

			e.stopPropagation();
		});
	} else {

		document.addEventListener('DOMContentLoaded', function () {
			// Sélectionner tous les éléments qui déclenchent l'ouverture des sous-menus
			const dropdownToggles = document.querySelectorAll('.dropdown-toggle');
			//const dropdownToggles = document.querySelectorAll('#main-navigation-menu .dropdown-toggle');
			dropdownToggles.forEach(function (toggle) {
				toggle.addEventListener('click', function (event) {
					const dropdownMenu = this.nextElementSibling;
					// Fermer tous les menus ouverts sauf celui actuellement cliqué
					dropdownToggles.forEach(function (otherToggle) {
						if (otherToggle !== toggle) {
							const otherMenu = otherToggle.nextElementSibling;
							otherMenu.classList.remove('show');
							otherMenu.style.display = 'none';
						}
					});
					// Basculer le menu associé au bouton sur lequel on a cliqué
					dropdownMenu.classList.toggle('show');
					dropdownMenu.style.display = dropdownMenu.classList.contains('show') ? 'block' : 'none';
					event.stopPropagation();
				});
			});
			// Fermer tous les menus si un clic est détecté en dehors des menus
			document.addEventListener('click', function () {
				const dropdownMenus = document.querySelectorAll('.dropdown-menu');
				dropdownMenus.forEach(function (menu) {
					menu.classList.remove('show');
					menu.style.display = 'none';
				});
			});

			// Empêcher les événements de clic sur les menus de se propager au document
			const dropdownMenus = document.querySelectorAll('.dropdown-menu');
			dropdownMenus.forEach(function (menu) {
				menu.addEventListener('click', function (event) {
					event.stopPropagation();
				});
			});
		});
	}

	$(".search__desktop i").click(function (e) {
		e.preventDefault();
		$(".searchbox").fadeIn(300);
	});

	$(".searchbox i").click(function (e) {
		e.preventDefault();
		$(".searchbox").fadeOut(300);
	});

	$('.toggle-accordion').click(function() {
		// Bascule la visibilité de la liste des villes
		$(this).next('.list-cities').slideToggle();
		// Bascule la classe pour l'animation de la flèche
		$(this).find('i').toggleClass('fa-chevron-down fa-chevron-up');
	});

});

//JS

document.addEventListener('DOMContentLoaded', function () {
	var closeButton = document.getElementById('closeButton');
	var topBar = document.getElementById('topBar');
	var mainContent = document.getElementById('mainContent');

	// Vérification si l'élément #closeButton existe
	if (closeButton && topBar && mainContent) {
		closeButton.addEventListener('click', function () {
			topBar.style.transition = 'height 0.3s linear, padding 0.3s linear';
			topBar.style.height = '0';
			topBar.style.padding = '0';
			mainContent.style.transition = 'padding-top 0.3s linear';
			mainContent.style.paddingTop = '0';
		});
	}
});

// Ajouter un écouteur d'événements à tous les boutons 'btn-cities'
document.querySelectorAll('.btn-cities').forEach(function(btn) {
	btn.addEventListener('click', function() {
		// Afficher la popup
		document.querySelectorAll('.popup-cities').forEach(function(popup) {
			popup.style.display = 'block';
		});
	});
});

// Ajouter un écouteur d'événements au bouton de fermeture
document.querySelectorAll('.close-btn').forEach(function(btn) {
	btn.addEventListener('click', function() {
		// Cacher la popup
		document.querySelectorAll('.popup-cities').forEach(function(popup) {
			popup.style.display = 'none';
		});
	});
});
